import {PageHead} from "@/components/page";

import {useRedirectToAuthorizedApp} from "./use-redirect-to-authorized-app";

const HomeScreen = () => {
  useRedirectToAuthorizedApp();

  return <PageHead pageTitle="kOS Home" />;
};

export {HomeScreen};
