import {useRouter} from "next/router";
import {useEffect, useMemo} from "react";

import {
  useAuth0AccessTokenClaims,
  useAuth0Auth
} from "@unlockre/utils-auth0/dist";

import {
  isKeywayAccessPermission,
  keywayPermissions
} from "@unlockre/utils-auth0/dist/keyway-permission";

import {
  assetManagerDefinition,
  dealRoomAdminDefinition,
  keybrainDefinition,
  propertySageDefinition,
  keycompsDefinition,
  keypilotAppDefinition,
  keydocsAppDefinition
} from "@unlockre/keyos-tools/dist/keyapp-definition";

import {useAuth0} from "@auth0/auth0-react";

const keyappPaths = {
  [keywayPermissions.keywayAccessAssetManager]:
    assetManagerDefinition.keyhubPath,
  [keywayPermissions.keywayAccessDealRoom]: dealRoomAdminDefinition.keyhubPath,
  [keywayPermissions.keywayAccessDealRoomAdmin]:
    dealRoomAdminDefinition.keyhubPath,
  [keywayPermissions.keywayAccessKeybrain]: keybrainDefinition.keyhubPath,
  [keywayPermissions.keywayAccessKeycomps]: keycompsDefinition.keyhubPath,
  [keywayPermissions.keywayAccessKeydocs]: keydocsAppDefinition.keyhubPath,
  [keywayPermissions.keywayAccessKeypilot]: keypilotAppDefinition.keyhubPath,
  [keywayPermissions.keywayAccessPropertySage]:
    propertySageDefinition.keyhubPath
};

const useRedirectToAuthorizedApp = () => {
  const router = useRouter();

  const {logout} = useAuth0();
  const {isAuthenticated} = useAuth0Auth();
  const auth0AccessTokenClaims = useAuth0AccessTokenClaims();

  const accessPermissions = useMemo(
    () =>
      isAuthenticated && auth0AccessTokenClaims.status === "succeeded"
        ? auth0AccessTokenClaims.data.permissions.filter(
            isKeywayAccessPermission
          )
        : null,
    [isAuthenticated, auth0AccessTokenClaims]
  );

  useEffect(() => {
    if (!accessPermissions) {
      return;
    }

    const defaultAppPath = accessPermissions.includes(
      keywayPermissions.keywayAccessKeypilot
    )
      ? keyappPaths[keywayPermissions.keywayAccessKeypilot]
      : keyappPaths[accessPermissions[0]];

    router.push(defaultAppPath);
  }, [router, accessPermissions, logout]);
};

export {useRedirectToAuthorizedApp};
